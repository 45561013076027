/* Railway Font */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@100;400;700&family=Quicksand:wght@300&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
);

/* Great Vibes Font */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@100;400;700&family=Great+Vibes&family=Quicksand:wght@300&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Fira Sans Condensed', sans-serif;
    font-family: 'Quicksand', sans-serif;
    font-family: 'Raleway', sans-serif;
  }
}